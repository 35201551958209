"use strict";

jQuery(document).ready(function ($) {
  $('.quote-slider-card').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    adaptiveHeight: true,
    prevArrow: $('.arrow-quote-prev'),
    nextArrow: $('.arrow-quote-next')
  });
});